import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import ColoredHeader from "../../../components/coloredHeader"
import FloatingSearch from "../../../components/floatingSearch"
import Button from "@material-ui/core/Button"
import Sydney from "../../../../static/background_images/australia-full-opacity.png"
import AusMap from "../../../../static/images/Aus-Map_5.png"
import BonaparteBasin from "../../../../static/images/Bonaparte-Basin"
import BeehiveInformationFlyer from "../../../../static/documents/Beehive_Information_flyer_1_Sept2021.pdf"
import BeehiveInformationFlyerDec from "../../../../static/documents/Beehive_Information_Flyer_2_01Dec21.pdf"
import BeehiveInformationFlyerFeb22 from "../../../../static/documents/Beehive_InformationFlyer3_28Feb2022.pdf"
import BeehiveInformationFlyerMay22 from "../../../../static/documents/Beehive_Information_Flyer_4_May22.pdf"
import BeehiveInformationFlyerJun22 from "../../../../static/documents/Beehive_Information_Flyer_5_27Jun2022.pdf"
import BeehiveInformationFlyerMay23 from "../../../../static/documents/Beehive_Information_Flyer_7_May2023.pdf"
import BeehiveInformationFlyerFeb24 from "../../../../static/documents/Beehive_Information_flyer_8-Feb2024.pdf"

import SummaryFCP from "../../../../static/documents/Fishing-Protocol-Summary-April-2023.pdf"

const BeehiveGeophysEpRev2 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive+Geophys+EP+Rev2.pdf"
const BeehiveGeotechEpRev3 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive_Geotech_EP_Rev3_18Dec2023.pdf"
const BeehiveDrillingEpRev6 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive_Drilling_EP_Rev6_17May2024.pdf"
const BeehiveDrillingOpepRev5_1 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive_Drilling_OPEP_Rev5-1_17May24.pdf"
const BeehiveMultiWellDrillingEpRev0 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive_MWDrilling_EP_Rev0_28June2024.pdf"
const BeehiveDrillingOpepRev6 =
  "https://eogresources.s3.us-east-2.amazonaws.com/australia/Beehive_MWDrilling_OPEP_Rev6_28June24.pdf"

export default function AreasOfOperation() {
  return (
    <Layout>
      <Helmet>
        <title>Areas of Operation - Australia – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Areas of Operation - Australia"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={Sydney}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page australia-page last-page">
        <div className="main-container">
          <div className="grid-area-1">
            <div>
              <h2>Australia</h2>
              <p>
                In Australia, a subsidiary of EOG holds a 100% interest in the
                WA-488-P Block, an exploration permit located offshore Western
                Australia. EOG intends to drill an exploration well targeting
                the Beehive Prospect in 2024-2026.
              </p>
            </div>
            <BonaparteBasin />
            <div className="australia-columns">
              <div>
                <h2 className="buttons">Information Flyers</h2>
                <div className="australia-page-buttons">
                  <Button
                    component="a"
                    className="australia-page-button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerFeb24}
                  >
                    Feb 2024
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerMay23}
                  >
                    May 2023
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerJun22}
                  >
                    June 2022
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerMay22}
                  >
                    May 2022
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerFeb22}
                  >
                    February 2022
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyerDec}
                  >
                    December 2021
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveInformationFlyer}
                  >
                    September 2021
                  </Button>
                </div>
              </div>
              <div>
                <h2 className="buttons">Environment Plans</h2>
                <div className="australia-page-buttons">
                  <Button
                    component="a"
                    className="australia-page-button"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveGeophysEpRev2}
                  >
                    Beehive Geophys EP (Rev2)
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveGeotechEpRev3}
                  >
                    Beehive Geotech EP (Rev3)
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveDrillingEpRev6}
                  >
                    Beehive-1 Drilling EP (Rev6)
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveDrillingOpepRev5_1}
                  >
                    Beehive-1 Drilling OPEP (Rev5.1)
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveMultiWellDrillingEpRev0}
                  >
                    Beehive Multi-Well Drilling EP (Rev0)
                  </Button>
                  <Button
                    component="a"
                    className="australia-page-button-gray"
                    style={{ width: "100%" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={BeehiveDrillingOpepRev6}
                  >
                    Beehive Multi-Well Drilling OPEP (Rev6)
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-area-2">
            <div className="card">
              <a href={AusMap} target="_aaoc">
                <img alt="Australia map" src={AusMap} />
              </a>
            </div>
            <div className="australia-page-action-items">
              <div className="australia-columns">
                <div>
                  <p>
                    Go here for more information and to provide feedback on our
                    Beehive activities
                  </p>
                  <Button
                    component="a"
                    className="australia-page-button"
                    href="https://klarite.mysocialpinpoint.com.au/beehive"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Social Pinpoint Consultation Hub
                  </Button>
                </div>
                <div>
                  <p>
                    Go here to download the summary of our Fisheries
                    Compensation Protocol
                  </p>

                  <Button
                    component="a"
                    className="australia-page-button"
                    href={SummaryFCP}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Summary FCP
                  </Button>
                </div>
              </div>

              <p>
                Additional information and documents can be found at Australia’s
                offshore energy regulators, NOPTA and NOPSEMA
              </p>
              <div className="buttons">
                <Button
                  component="a"
                  className="australia-page-button"
                  href="https://public.neats.nopta.gov.au/Opggs/Title/ae13ae9d-f7f9-4b2e-9e4d-785ee9ceb9b1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WA-488-P Permit Details
                </Button>{" "}
                <Button
                  component="a"
                  className="australia-page-button"
                  href="https://info.nopsema.gov.au/environment_plans/568/show_public"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beehive Geophys Environment Plan
                </Button>
              </div>
              <div className="buttons">
                <Button
                  component="a"
                  className="australia-page-button"
                  href="https://info.nopsema.gov.au/environment_plans/605/show_public"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beehive Geotech Environment Plan
                </Button>
                <Button
                  component="a"
                  className="australia-page-button"
                  href="https://info.nopsema.gov.au/environment_plans/643/show_public"
                  target="_blank"
                  rel="noopener noreferrer"

                >
                  Beehive-1 Drilling Environment Plan
                </Button>
              </div>
              <div className="buttons">
                <Button
                  component="a"
                  className="australia-page-button"
                  href="https://info.nopsema.gov.au/environment_plans/678/show_public"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beehive Multi-Well Drilling Environment Plan
                </Button>
                <div className="australia-page-button-blank">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
